<template>
    <div class="container">
        <div class="register-fixed">
            <div class="register-con">
                <div style="display: flex;justify-content: center;">
                    <div style="padding: 0 35px;">
                        <phone-code v-model="form" @hasUser="hasUser"></phone-code>
                    </div>
                </div>

                <div :class="['register-button', disable?'disabled':'']" :disabled="disable">立即注册</div>
            </div>
        </div>
    </div>
</template>

<script>
    import PhoneCode from '@/views/components/register/PhoneCode.vue'
    import {phoneCodeRegister} from "@/api/user";

    export default {
        name: "EarlyBird-Register",
        components: {
            PhoneCode
        },
        data() {
            return {
                form: {
                    phone: '',
                    code: '',
                    codeData: ''
                },
                //邀请码
                inviteCode: ''
            }
        },
        computed: {
            disable() {
                return !(this.form.phone && this.form.code);
            }
        },
        created() {
            this.inviteCode = this.$route.query.inviteCode
        },
        methods: {
            hasUser() {
                this.$successToast("手机号已注册，请直接登录~");
            },
            //注册
            register() {
                if (!this.phoneNumber) {
                    this.$failToast("请输入手机号");
                    return;
                }
                if (!this.codeValue) {
                    this.$failToast("请输入验证码");
                    return;
                }
                phoneCodeRegister({
                    phone: this.form.phone,
                    phoneCode: this.form.code,
                    captchaCode: this.form.code,
                    inviteCode: this.inviteCode,
                }).then(() => {
                    this.$successToast("注册成功");
                    this.$router.push({
                        name: "RegisterSuccess",
                    });
                });
            },
        },
    };
</script>
<style lang="scss" scoped>
    /*.phone-code-container /deep/.item{*/
    /*  background: #F2F2F2;*/
    /*  margin-bottom: 10px;*/
    /*}*/
    /*/deep/.van-field{*/
    /*  background: #F2F2F2;*/
    /*}*/

    .container{
        // position: fixed;

        padding: 20px 20px 100px    ;
    }

    .register-fixed {
        position: relative;
        width: 100%;
        height: 640px;
        background: url("https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/register.png") no-repeat;
        background-size: 100% 100%;
    }

    .register-con {
        position: absolute;
        bottom: 55.5px;
        // width: 100%;
        width: 335px;
        
    }

    .register-bot {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .register-style {
        width: 80%;
        //   width: 80%;
        //   font-size: 12px;
        //   margin-top: 20px;
        //   background: #f2f2f2;
        border: 1px solid red;
        //   display: flex;
        //   align-items: center;
    }

    .register-button {
        width: 80%;
        height: 44px;
        background: #ffa164;
        border-radius: 22px;
        font-size: 17px;
        line-height: 44px;
        text-align: center;
        color: #fff;

        margin: 0 auto;
        margin-top: 27px;
        font-weight: bold;
    }
    .disabled{
        background-color: #F2F2F2;
    }
</style>
